<template
>
  <div
    class="text-center"
  >
    <v-dialog
      v-model="showOTPDialog"
      width="400"
      persistent
    >
      <v-card
        elevation="8"
      >
        <div
          class="pa-6"
        >
          <p
            class="h5 font-weight-bold"
          >
            Pengesahan OTP
          </p>

          <p
            v-if="!allowOTPResend" class="body-1 text-center mt-4"
          >
            <span
            >
              {{ params.message }}
            </span>
            <br/>
          </p>

          <v-divider
          ></v-divider>

          <div
            v-if="!isIdle"
            class="ma-auto"
            style="max-width: 300px"
          >
            <v-otp-input
              v-model="otp"
              length="6"
              type="number"
              @finish="onFinishOTP"
            ></v-otp-input>
            <!-- <v-overlay absolute :value="isLoading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay> -->
          </div>

          <countdown
            ref="countdown"
            :time="180 * 1000"
            :transform="transform"
            :emitEvents=true
            :autoStart=true
            @progress="onCountdownProgress"
            @start="onCountdownStart"
            @end="onCountdownEnd"
            @abort="onCountdownAbort"
          >
            <template
              slot-scope="props"
            >
              <h5
                class="text-center font-weight-bold"
              >
                {{ props.minutes }}:{{ props.seconds }}
                <!-- <br/>{{props.totalSeconds}} -->
              </h5>
            </template>
          </countdown>

          <div
            v-if="!isLoading"
          >
            <p
              v-if="allowOTPResend"
              class="text-center mt-4"
            >
              Kod OTP tidak diterima?
              <!-- <span class="font-weight-bold pointer" @click="resendOTP()">Hantar semula</span> -->
            </p>
            <!-- <p v-if="!allowOTPResend" class="text-center font-weight-bold">
              {{ timer + " saat" }}
            </p> -->
          </div>

          <div
            v-if="isLoading"
            class="text-center mt-4"
          >
            <v-progress-circular
              indeterminate color="primary"
            ></v-progress-circular>
          </div>

          <v-divider
          ></v-divider>

          <div
            class="d-flex flex-wrap justify-content-end align-items-center"
          >
            <v-btn
              v-if="allowOTPResend"
              @click="resendOTP()"
            >
              <span
                class="font-weight-bold"
              >
                Hantar semula
              </span>
            </v-btn>

            <v-spacer
            ></v-spacer>

            <v-btn
              @click="closeOTPDialog()"
            >
              <span
                class="font-weight-bold"
              >
                Batal
              </span>
            </v-btn>
          </div>

        </div>
      </v-card>
    </v-dialog>

    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

import {
  mapState,
  mapGetters,
} from "vuex";

import {
  RESEND_OTP,
} from "@/core/services/store/otp.module";

import {
  VERIFY_OTP,
} from "@/core/services/store/otp.module";

import {
  SET_OTP_AUTH,
} from "@/core/services/store/auth.module";

Vue.component(VueCountdown.name, VueCountdown);

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    params: {
      type: Object,
      default() {
        return {
          message:
            "OTP telah dihantar ke telefon bimbit anda. Sila hubungi unit bantuan teknikal di talian 03-7803 8495 untuk kemaskini nombor telefon bimbit anda.",
          nric: "",
          run: 0,
          route: {},
        };
      },
    },

    showNumber: {
      type: Boolean,
      default: false,
    },

    notILMS: {
      type: Boolean,
      default: false,
    }
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue) {
        this.otp = null;
        this.isLoading = false;
        if (this.$refs.countdown != undefined) {
          this.$refs.countdown.restart();
        }
      }
    },

    params(newValue, oldValue) {
      this.theData = newValue;
      //nfh-console.log('Params: ', newValue, this.theData);
    },
  },

  data() {
    return {
      otp: null,
      isIdle: false,
      overlay: false,
      isLoading: false,
      allowOTPResend: false,

      theData: {
        message: "",
        nric: "",
      },
    };
  },

  computed: {
    ...mapGetters([
      "timer",
      "response"
    ]),

    showOTPDialog: {
      get() {
        return this.value;
      },

      set(newValue) {
        if (newValue) {
          this.otp = null;
          this.isLoading = false;
        }
        // this.otp = newValue == true ? null : newValue;
        // this.isLoading = !!!newValue;
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value; // Adds leading zero
        props[key] = `${digits}`;
      });
      return props;
    },

    onCountdownProgress(data) {
      if (data.minutes < 1 && data.seconds < 10) {
        this.allowOTPResend = true;
      }
    },

    onCountdownStart(data) {
      // xxconsole.log('Countdown Started')
      this.isIdle = false;
    },

    onCountdownAbort(data) {
      // xxconsole.log('Countdown Aborted')
      this.isIdle = true;
    },

    onCountdownEnd(data) {
      // xxconsole.log('Countdown Ended')
      this.isIdle = true;
      setTimeout(() => {
        if (this.isIdle) {
          this.closeOTPDialog();
        }
      }, (20 * 1000));
    },

    resendOTP() {
      this.isLoading = true;
      this.isIdle = false;
      let _opt = {
        nric: this.params.nric,
        options: [],
      };

      if (this.notILMS) {
        _opt.options.push('notilms');
      }

      this.$store.dispatch(RESEND_OTP, _opt)
        .then((res) => {
          if (res.data.success) {
            this.params.message = res.data.message;
            this.$refs.countdown.restart();
            // this.isLoading = false;
          }
          else {
            this.$emit("onErrorOTP", res.data.errcode);
            this.closeOTPDialog();
            // switch (res.data.error) {
            //   case 'not_due_for_resend':
            //     alert('ERROR: OTP is still valid and not need to request new');
            //     break;
            //   default:
            //     alert('ERROR: Error while requesting new OTP\n' + res.data.error);
            // }
            // this.allowOTPResend = false;
          }
          // this.isLoading = false;
        })
        .catch((err) => {
          this.$emit("onErrorOTP", err);
        })
        .finally(() => {
          this.allowOTPResend = false;
          this.isLoading = false;
        });
    },

    closeOTPDialog() {
      this.allowOTPResend = false;
      this.showOTPDialog = false;
      this.isLoading = false;
    },

    async onFinishOTP() {
      // this.showOTPDialog = false;
      // this.overlay = true;
      this.$emit("onFinishOTP", this.otp);
      // this.overlay = false;
      // const routeTo = {
      //   name: "Dashboard",
      // };
      // const params = {
      //   nric: this?.params?.nric,
      //   otp: this.otp,
      // };

      // await this.$store.dispatch(VERIFY_OTP, params)
      //   .then(async (resp) => {
      //     if (resp?.data?.success) {
      //       await this.$store.commit(SET_OTP_AUTH, resp.data);
      //       await this.$store.dispatch(GET_LOOKUPS);

      //       if (this?.$route?.query?.act) {
      //         routeTo.query = this.$route.query;
      //       }

      //       // this.overlay = false;
      //       await this.$router.push(routeTo);
      //     }
      //     else {
      //       switch (resp.data.errcode) {
      //         case "otp_unmatched_or_expired":
      //           errorMessages("OTP tidak sepadan atau telah tamat tempoh.");
      //           break;

      //         case "authentication_failed":
      //           errorMessages("Could not authenticate");
      //           break;

      //         case "authentication_unexpected":
      //         case "catch_error":

      //         default:
      //           errorMessages("Berlaku ralat ketika membuat pengesahan data.");
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //   })
      //   .finally(() => {
      //     this.overlay = false;
      //   });
    },
  },

  mounted() {
    this.theData = this.params;
  }
};
</script>
