var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "400", persistent: "" },
          model: {
            value: _vm.showOTPDialog,
            callback: function ($$v) {
              _vm.showOTPDialog = $$v
            },
            expression: "showOTPDialog",
          },
        },
        [
          _c("v-card", { attrs: { elevation: "8" } }, [
            _c(
              "div",
              { staticClass: "pa-6" },
              [
                _c("p", { staticClass: "h5 font-weight-bold" }, [
                  _vm._v(" Pengesahan OTP "),
                ]),
                !_vm.allowOTPResend
                  ? _c("p", { staticClass: "body-1 text-center mt-4" }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.params.message) + " "),
                      ]),
                      _c("br"),
                    ])
                  : _vm._e(),
                _c("v-divider"),
                !_vm.isIdle
                  ? _c(
                      "div",
                      {
                        staticClass: "ma-auto",
                        staticStyle: { "max-width": "300px" },
                      },
                      [
                        _c("v-otp-input", {
                          attrs: { length: "6", type: "number" },
                          on: { finish: _vm.onFinishOTP },
                          model: {
                            value: _vm.otp,
                            callback: function ($$v) {
                              _vm.otp = $$v
                            },
                            expression: "otp",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("countdown", {
                  ref: "countdown",
                  attrs: {
                    time: 180 * 1000,
                    transform: _vm.transform,
                    emitEvents: true,
                    autoStart: true,
                  },
                  on: {
                    progress: _vm.onCountdownProgress,
                    start: _vm.onCountdownStart,
                    end: _vm.onCountdownEnd,
                    abort: _vm.onCountdownAbort,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "h5",
                            { staticClass: "text-center font-weight-bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(props.minutes) +
                                  ":" +
                                  _vm._s(props.seconds) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                !_vm.isLoading
                  ? _c("div", [
                      _vm.allowOTPResend
                        ? _c("p", { staticClass: "text-center mt-4" }, [
                            _vm._v(" Kod OTP tidak diterima? "),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "text-center mt-4" },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-divider"),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-wrap justify-content-end align-items-center",
                  },
                  [
                    _vm.allowOTPResend
                      ? _c(
                          "v-btn",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.resendOTP()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(" Hantar semula "),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.closeOTPDialog()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(" Batal "),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }